.home__content {
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 5.5rem;
  column-gap: 2rem;
  align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home__social-icon {
  font-size: 1.25rem;
  color: var(--title-color);
}

.home__social-icon:hover {
  color: var(--title-color-dark);
}

.home__title {
  font-size: var(--big-font-size);
  margin-bottom: var(--mb-0-25);
}

.home__subtitle {
  position: relative;
  font-size: var(--h3-font-size);
  padding-left: 5.4rem;
  font-weight: var(--font-normal);
  margin-bottom: var(--mb-1);
}

.home__subtitle::before {
  content: "";
  position: absolute;
  width: 70px;
  height: 1px;
  background-color: var(--text-color);
  left: 0;
  top: 1rem;
}

.typewriter-cursor {
  display: inline-block;
  width: 2px; 
  height: 1.05em; 
  background-color: currentColor;
  margin-left: 2px; 
  animation: blink 2s infinite step-start;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}


.home__description {
  max-width: 400px;
  margin-bottom: var(--mb-3);
}

.home__img {
  background: url(../../assets/raj.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
  order: 1;
  justify-self: center;
  width: 300px;
  height: 300px;
  will-change: border-radius;
}


.home__container {
  row-gap: 7rem;
}

.home__scroll {
  margin-left: 9.25rem;
}

.wheel {
  animation: scroll 2s ease infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(3.75rem);
  }
}

.home__scroll-name {
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
  margin-left: 2px;
}

.home__scroll-arrow {
  font-size: 1.25rem;
  color: var(--title-color);
}

.typewriter {
  overflow: hidden; /* Ensures the content is hidden when outside the box */
  border-right: 0.15em solid var(--text-color); /* Cursor effect */
  white-space: nowrap; /* Keeps the content on a single line */
  animation: typing 6s steps(30, end) infinite, blink-caret 0.75s step-end infinite;
  width: 0; /* Initial width for the typing effect */
}

/* Typing animation */
@keyframes typing {
  0% {
    width: 0;
  }
  40% {
    width: 100%;
  }
  60% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

/* Cursor blink animation */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--text-color);
  }
}

@media screen and (max-width: 992px) {
    .home__content {
      grid-template-columns: 100px repeat(2, 1fr);
      column-gap: 1.25rem;
    }
  
    .home__subtitle {
      padding-left: 3.75rem;
      margin-bottom: var(--mb-1);
    }
  
    .home__subtitle::before {
      width: 42px;
      top: 0.8rem;
    }
  
    .home__description {
      max-width: initial;
      margin-bottom: var(--mb-2-5);
    }
  
    .home__img {
      width: 250px;
      height: 250px;
      box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%);
    }
  
    .home__scroll {
      margin-left: 7.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    .home__content {
      grid-template-columns: 0.5fr 3fr;
      padding-top: 3.5rem;
    }
  
    .home__img {
      order: initial;
      justify-self: initial;
    }
  
    .home__data {
      grid-column: 1/3;
    }
  
    .home__img {
      box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
      width: 200px;
      height: 200px;
    }
  
    .home__scroll {
      display: none;
    }
  }
 
  @media screen and (max-width: 350px) {
    .home__img {
      width: 180px;
      height: 180px;
    }
  }
  