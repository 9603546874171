.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__item {
  background: var(--container-color);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
  margin-bottom: 1rem;
}

.portfolio__item-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.portfolio__item h3 {
  font-size: var(--h3-font-size);
  margin-bottom: 0.5rem;
}

.portfolio__item p {
  font-size: var(--small-font-size);
  color: var(--text-color);
  margin-bottom: 1rem;
}

.portfolio__item-cta {
  display: flex;
  justify-content: center;
}

.btn {
  display: inline-block;
  background-color: var(--title-color);
  color: var(--container-color);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  font-weight: var(--font-medium);
  transition: all 0.3s ease-in-out;
}

.btn:hover {
  background-color: var(--title-color-dark);
}

/* Media Queries */
@media screen and (max-width: 992px) {
  .portfolio__container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .portfolio__container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 350px) {
  .portfolio__item {
    padding: 1rem;
  }
}

@media screen and (max-width: 992px) {
  .portfolio__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .portfolio__item {
    padding: 1rem;
  }
}